import {createApp} from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import { myAxios } from './utils/network'
import VueAxios from 'vue-axios'

var app = createApp(App)

app.use(store).use(router).use(VueAxios, myAxios).mount('#app')
