import { createRouter, createWebHistory } from "vue-router"

const loginPage = () => import('../page/LoginPage.vue');
const workPage = () => import('../page/WorkPage.vue');
const testPage = () => import('../page/TestPage.vue');
const homePage = () => import('../page/HomePage.vue');
const editPage = () => import('@/page/EditPage.vue');


const routes = [
    {
        path: '/home',
        name: 'home', 
        component: homePage,
    },
    {
        path: '/',
        redirect: {
          name: 'home'
        },
    },
    {   
        path: '/login',
        name: 'login',
        component: loginPage,
    },
    {
        path: '/work',
        name: 'work',
        component: workPage,
    },
    {
        path: '/test',
        name: 'test',
        component: testPage,
    },
    {
        path: "/edit",
        name: "edit",
        component: editPage,
      }
]

const router = createRouter(
    {
        history: createWebHistory(process.env.BASE_URL), //这行很关键好吧！没了这行路由不出页面，不知道为什么？？？
        routes: routes
    }
)

// router.beforeEach((to, from, next) => {
//     //to到哪儿  from从哪儿离开  next跳转 为空就是放行  
//     if (to.path === '/' || to.path === '/login') {
//         //如果跳转为登录，就放行 
//         next();    
//     } else {
//         //取出localStorage判断
//         let token = localStorage.getItem('token');      	     
//         if (token == null || token === '') { 
//                 console.log('请先登录')       
//                 next({name: 'login'});
//             } else {
//                 next();   
//             }   
//     }});

export default router;

