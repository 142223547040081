export default {
  isStrEmpty: function(str) {
    if (str == null || str.length == 0) return true
    return false
  },
  isNumeric: function(str) {
    return isNaN(Number(str,10))
  },
  alertBadResponse: function(response, msg) {
    if (response == undefined) {
      if (msg == undefined) {
        msg = "连接有误"
      }
      alert(msg)
      return false
    }
  }
}