import axios from "axios";
import { MD5 } from "./encrypt";
import { uuid } from "./uuid";
import constant from "./constant";
import router from '../router'


export const myAxios = axios.create({
  baseURL: 'http://127.0.0.1:8085/api',
  timeout: 5000,
})

//拦截请求
myAxios.interceptors.request.use(config => {
    //放token到header
    let token = localStorage.getItem('token')
    let tokenName = localStorage.getItem("tokenName")
    if (token != null) {
      config.headers[tokenName] = token
    }

    return config 
  },
  error => {
    return Promise.reject(error)
  }
)

//拦截响应
myAxios.interceptors.response.use(res => {
  //token不对就跳到登录页重新登录
  if (res.data.code == constant.TOKEN_ERROR) {
    console.log("要重新登录")
    localStorage.removeItem("token")
    localStorage.removeItem("tokenName")
    router.push('login') 
  } 
  return res
},
error => {
   console.log(error)
}
)

export function get(apiPath, params) {
  params["nonce"] = uuid()
  params["timestamp"] = new Date().getTime()
  let sign = MD5(params, constant.SIGN_KEY)
  params["sign"] = sign
  return myAxios.get(apiPath, {params: params})
}

export function post(apiPath, params) {
  return basePost(apiPath, params, false)
}

export function postInQuery(apiPath, params) {
  return basePost(apiPath, params, true)
}

function basePost(apiPath, params, isQuery) {
  params["nonce"] = uuid()
  params["timestamp"] = new Date().getTime()
  let sign = MD5(params, constant.SIGN_KEY)
  params["sign"] = sign
  if (isQuery) {
    return myAxios.post(apiPath, buildQueryParamsStr(params))
  } else {
    return myAxios.post(apiPath, params)
  }
}

function sortObj(obj) {
  let sorted = Object.keys(obj).sort((a,b) => {
    return b - a
  })
  let newObj = Object();
  for (var key in sorted) {
    newObj[sorted[key]] = obj[sorted[key]] 
  }
  return newObj
  // return obj
}

function buildQueryParamsStr(obj) {
  let str = ""
  for (let key in obj) {
      let val = obj[key]
      // console.log(val)
      str += (key + "=" + val + "&")
  }
  return str.slice(0, str.length - 1)
}

function urlencode (str) {  
  str = (str + '').toString();   
  return encodeURIComponent(str).replace(/!/g, '%21').replace(/'/g, '%27').replace(/\(/g, '%28').  
  replace(/\)/g, '%29').replace(/\*/g, '%2A').replace(/%20/g, '+');  
}

