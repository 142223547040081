import CryptoJS from 'crypto-js'
import  JSEncrypt  from 'jsencrypt'
import jsrsasign from 'jsrsasign'
import  {Base64}  from './base64'
import constant from './constant'

/**
 * 加盐MD5加密，可以作为加签算法
 * @param {加密对象} obj 
 * @param {*} slat 
 */
export function MD5(obj, slat) {
  let str = ""
  for (let key in obj) {
      str += (key + "=" + obj[key] + "&")
  }
  str += ("key" + "=" + slat)
  let arr = []
  for (let char in str) {
    arr.push(str.charAt(char))
  }
  arr.sort((a, b) => {
    return a.charCodeAt() - b.charCodeAt()
  })
  let sortedStr = "";
  for (let ele in arr) {
    sortedStr += arr[ele]
  }
  // console.log(sortedStr)
	// MD5加密后，转成字符串
  // return str
	return CryptoJS.MD5(sortedStr).toString();
}

export function RSAEncrypt(msg, publicKey) {
  const jsencrypt = new JSEncrypt()
  // console.log("原始pubkey：" + publicKey)
  jsencrypt.setPublicKey(publicKey)
  let rsaMsg = jsencrypt.encrypt(msg)
  let rsaMsgProcessed = rsaMsg.replaceAll("+", "@")
  return rsaMsgProcessed
}

export function rsaEncrypt(msg, publicKey) {
  let pemKey = '-----BEGIN PUBLIC KEY-----\n' + publicKey + "\n-----END PUBLIC KEY-----"
  // console.log(jsrsasign.KEYUTIL.getPEM(pemKey))
  var pub = jsrsasign.KEYUTIL.getKey(pemKey);
  var enc = jsrsasign.KJUR.crypto.Cipher.encrypt(msg,pub);
  // console.log("enc:" + enc)
  return enc
}

export function base64(str) {
  let trans = CryptoJS.enc.Utf8.parse(str);
  return CryptoJS.enc.Base64.stringify(trans);
}

export function aesEncrypt(data) {
  return basicAesEncrypt(data, constant.AES_KEY, constant.AES_IV)
}

export function aesDecrypt(data) {
  return basicAesDecrypt(data, constant.AES_KEY, constant.AES_IV)
}


export function basicAesEncrypt(data, key, iv) {
  const srcs = CryptoJS.enc.Utf8.parse(data)
  const encrypted = CryptoJS.AES.encrypt(srcs, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.toString()
}

export function basicAesDecrypt(data, key, iv) {
  const decrypted = CryptoJS.AES.decrypt(data, key, {
      iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
  })
  return CryptoJS.enc.Utf8.stringify(decrypted).toString()
}

function base64toHEX(base64) {
  var raw = atob(base64);
  var HEX = '';
  for (var i = 0; i < raw.length; i++ ) {
    var _hex = raw.charCodeAt(i).toString(16)
    HEX += (_hex.length==2?_hex:'0'+_hex);
  }
  return HEX.toUpperCase();
}


