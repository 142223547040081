import { createStore } from 'vuex'
// import createPersistedState from "vuex-persistedstate"
import user from './modules/user'
import workpage from './modules/workpage'

export default createStore({
  modules: {
    user,
    workpage,
  },
  // plugins: [createPersistedState()]
  // state: {
  // },
  // getters: {
  // },
  // mutations: {
  // },
  // actions: {
  // },
  // modules: {
  // }
})
