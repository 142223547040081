export default {
  vlogin: "/vLogin",
  plogin: "/pLogin",
  regist: "/register",
  logout: "/logout",
  addNoteCategory: '/addNoteCategory',
  getNoteCategory: '/getNoteCategory',
  userinfo: '/userinfo',
  getArticle: '/getArticle',
  getArticleCovers: '/getArticleCovers',
  getPublicKey: "/getPublicKey",
  saveDraftNews: "/saveDraftNews",

}
