
const state = {
  isPasswordLogin: true, //登录页那里时密码登录还是验证登录的一个标识位
}

const mutations = {
  setIsPasswordLogin(state, isPasswordLogin) {
    state.isPasswordLogin = isPasswordLogin
  }
}

const getters = {
  
}

const actions = {

}
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}