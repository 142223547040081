<template>
  <div id="app">
    <router-view/>
  </div>

</template>

<script>
import api from './utils/api'
import methods from './utils/methods'
import constant from './utils/constant'

export default {
    components: {
      
    },
    provide() {
      return {
        $method: methods,
        $api: api,
        $constant: constant, 
      }
    },
}
</script>

<style>   
#app {
  font-family:'Times New Roman', Times, serif
}
* {
  /* padding: 0px;
  margin: 0px;   */
  font-family: sans-serif;
  box-sizing: border-box;
}

/* div {
  position: relative;
} */
/* p {
  margin: 0px;
  display: inline;
} */
.teco-txt-color {
  color: #303133;
}

::-webkit-scrollbar{display:none}
</style>
