const state = {
  category: "",
  workCompName: "", //切换组件名
  itemTitle: "", // 查看词条的标题
  itemContent: "", //查看词条的内容
}

const mutations = {
  setCategory (state, category) {
    state.category = category;
  },
  setWorkCompName(state, compName) {
      state.workCompName = compName;
  },
  setItemTitle(state, title) {
    state.itemTitle = title;
  },
  setItemContent(state, content) {
    state.itemContent = content;
  }
}

const getters = {
  
}

const actions = {
  
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
